<template>
    <div class="question_info">
        <!-- 题目的详情展示 -->
        <h3 class="name">题目:{{item.title}} (总分{{item.score}})</h3>
        <div class="daan">
             答案: <div v-html="item.answer"></div>  
        </div>
        <div class="pf">评分标准:{{item.biaozhun}}</div>  
    </div>
</template>
<script>
export default {
    name:"QuestionInfo",
    data:function(){
        return {
            item:{}
        }
    },
    created() {
        this.item = this.$route.query.data;
    },
}
</script>
<style lang="less" scoped>
    .question_info {
        padding: 30px;
        .name {
            color: #555;
        }
        .daan{
            font-size: 16px;
            color: #aaff;
            margin: 10px 7px;
            span {
                font-size: 16px !important;
                color: #aaff !important;
            }
        }
        .pf {
            color: orangered;
            font-size: 12px;
            margin: 0 7px;
        }
    }
</style>